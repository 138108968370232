var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AdminTemplate',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pageLoad),expression:"pageLoad"}]},[(_vm.countDown)?_c('div',{staticClass:"countLoading"},[_c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"text-primary font-64"},[_vm._v("รอเฉลย...")]),_c('div',{staticClass:"cicle-count mg-x-auto mg-t-1",staticStyle:{"font-size":"104px"}},[_vm._v(" "+_vm._s(_vm.countTime)+" ")])])]):_c('el-col',{attrs:{"span":18,"offset":3}},[_c('h1',{staticClass:"text-center mg-y-5"},[_vm._v(_vm._s(_vm.exam.question))]),_c('div',{staticClass:"rank-score"},[_c('el-row',{staticClass:"h-100"},[_c('el-col',{staticClass:"h-100",attrs:{"offset":3,"span":6}},[_c('div',{staticClass:"pd-x-1 h-100 is-flex ai-end flex-warp js-center text-primary text-center ai-content-end"},[_c('div',{staticClass:"w-100 graph-size"},[(_vm.exam.answer == 1 && _vm.answer)?_c('i',{staticClass:"el-icon-success",staticStyle:{"font-size":"44px"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.userAnswer.one.length)+" ")]),_c('div',{staticClass:"score bg-primary w-100",class:{ 'cr-pointer': _vm.answer },style:((_vm.userAnswer.one.length * 100) / _vm.total > 75
                  ? 'height:' + 55 + '%'
                  : (_vm.userAnswer.one.length * 100) / _vm.total > 0
                  ? 'height:' + (_vm.userAnswer.one.length * 100) / _vm.total + '%'
                  : 'height:2%')}),_c('div',[_c('p',{staticClass:"mg-b-0 mg-t-6 text-center text-grey font-28"},[_vm._v(" ข้อที่ 1 ")]),_c('p',{staticClass:"mg-y-0 text-primary font-32 cr-pointer m-h-35"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.answer &&
                    typeof _vm.exam.picAnswer != 'undefined' &&
                    _vm.exam.picAnswer.q1 != ''
                  ),expression:"\n                    answer &&\n                    typeof exam.picAnswer != 'undefined' &&\n                    exam.picAnswer.q1 != ''\n                  "}],on:{"click":function($event){_vm.answer ? _vm.openModal('q1') : ''}}},[_vm._v("ดูคำอธิบาย")])])])])]),_c('el-col',{staticClass:"h-100",attrs:{"span":6}},[_c('div',{staticClass:"pd-x-1 h-100 is-flex ai-end flex-warp js-center text-danger text-center ai-content-end"},[_c('div',{staticClass:"w-100 graph-size"},[(_vm.exam.answer == 2 && _vm.answer)?_c('i',{staticClass:"el-icon-success",staticStyle:{"font-size":"44px"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.userAnswer.two.length)+" ")]),_c('div',{staticClass:"score bg-danger w-100",class:{ 'cr-pointer': _vm.answer },style:((_vm.userAnswer.two.length * 100) / _vm.total > 75
                  ? 'height:' + 55 + '%'
                  : (_vm.userAnswer.two.length * 100) / _vm.total > 0
                  ? 'height:' + (_vm.userAnswer.two.length * 100) / _vm.total + '%'
                  : 'height:2%')}),_c('div',[_c('p',{staticClass:"mg-b-0 mg-t-6 text-center text-grey font-28"},[_vm._v(" ข้อที่ 2 ")]),_c('p',{staticClass:"mg-y-0 text-primary font-28 cr-pointer m-h-35"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.answer &&
                    typeof _vm.exam.picAnswer != 'undefined' &&
                    _vm.exam.picAnswer.q2 != ''
                  ),expression:"\n                    answer &&\n                    typeof exam.picAnswer != 'undefined' &&\n                    exam.picAnswer.q2 != ''\n                  "}],on:{"click":function($event){_vm.answer ? _vm.openModal('q2') : ''}}},[_vm._v("ดูคำอธิบาย")])])])])]),_c('el-col',{staticClass:"h-100",attrs:{"span":6}},[_c('div',{staticClass:"pd-x-1 h-100 is-flex ai-end flex-warp js-center text-warning text-center ai-content-end"},[_c('div',{staticClass:"w-100 graph-size"},[(_vm.exam.answer == 3 && _vm.answer)?_c('i',{staticClass:"el-icon-success",staticStyle:{"font-size":"44px"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.userAnswer.three.length)+" ")]),_c('div',{staticClass:"score bg-warning w-100",class:{ 'cr-pointer': _vm.answer },style:((_vm.userAnswer.three.length * 100) / _vm.total > 75
                  ? 'height:' + 55 + '%'
                  : (_vm.userAnswer.three.length * 100) / _vm.total > 0
                  ? 'height:' + (_vm.userAnswer.three.length * 100) / _vm.total + '%'
                  : 'height:2%')}),_c('div',[_c('p',{staticClass:"mg-b-0 mg-t-6 text-center text-grey font-28"},[_vm._v(" ข้อที่ 3 ")]),_c('p',{staticClass:"mg-y-0 text-primary font-28 cr-pointer m-h-35"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.answer &&
                    typeof _vm.exam.picAnswer != 'undefined' &&
                    _vm.exam.picAnswer.q3 != ''
                  ),expression:"\n                    answer &&\n                    typeof exam.picAnswer != 'undefined' &&\n                    exam.picAnswer.q3 != ''\n                  "}],on:{"click":function($event){_vm.answer ? _vm.openModal('q3') : ''}}},[_vm._v("ดูคำอธิบาย")])])])])]),_c('el-dialog',{attrs:{"visible":_vm.modalAnswer,"width":"65%","top":"5vh"},on:{"update:visible":function($event){_vm.modalAnswer=$event}}},[_c('div',[(_vm.imgAnswer)?_c('img',{staticClass:"mw-100",attrs:{"src":require('@/assets/exam/picture/' + _vm.exam.picAnswer[_vm.imgAnswer]),"alt":""}}):_vm._e()])])],1)],1),_c('el-col',{attrs:{"span":12,"offset":6}},[_c('el-button',{staticClass:"w-100 mg-t-3 mg-b-5",attrs:{"type":"primary"},on:{"click":function($event){_vm.answer == false ? _vm.countdown() : _vm.showRank()}}},[_vm._v(_vm._s(_vm.answer ? "สรุปคะแนน" : "เฉลย"))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }